(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/create-game/assets/javascripts/services/update-game.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/create-game/assets/javascripts/services/update-game.js');
"use strict";


const {
  jupiter
} = svs.core;
const {
  resolveTimeZone
} = svs.components.lbUtils.helpers.formatDate;
const updateGame = (_ref, callback) => {
  let {
    activatedDrawId,
    game
  } = _ref;
  jupiter.put({
    path: "/retailer/1/marketplace/activateddraws/".concat(activatedDrawId),
    data: {
      buyinCloseTime: game.buyinCloseTime && dateFns.formatISO(resolveTimeZone(game.buyinCloseTime)),
      description: game.description,
      newCommissionPercent: game.fee
    }
  }, () => callback(undefined, game, activatedDrawId), error => callback(error.responseJSON.error, undefined));
};
setGlobal('svs.components.marketplaceRetailAdminData.createGame.services.updateGame', updateGame);

 })(window);