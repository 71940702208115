(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/create-game/assets/javascripts/create-game-actions.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/create-game/assets/javascripts/create-game-actions.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }


const {
  errorCodeHandler
} = svs.components.marketplaceData;
const {
  selectors
} = svs.components.marketplaceRetailAdminData;
const {
  getGame
} = svs.components.marketplaceData.selectors.games;
const {
  services
} = svs.components.marketplaceRetailAdminData.createGame;
const {
  createBetProposal,
  updateBetProposal
} = svs.components.marketplaceData.createBetProposal.actions;
const {
  services: gameServices
} = svs.components.marketplaceRetailAdminData.games;
const {
  dialogWrapper
} = svs.components.marketplaceData;
const {
  editorToJson
} = svs.components.marketplaceRetailAdmin.richText.helpers;
const {
  getDraw
} = svs.components.marketplaceData.selectors.draws;
const {
  getWeek,
  subMinutes
} = dateFns;
const {
  sportProducts
} = svs.components.lbUtils;
const {
  parseGameType
} = svs.racing.raceService.utils.parse;
const {
  getProductName
} = svs.racing.common.strings;
const CHANGE_DRAW = 'CREATE_GAME/CHANGE_DRAW';
const changeDraw = (drawNumber, productId) => ({
  type: CHANGE_DRAW,
  drawNumber,
  productId
});
const CHANGE_BUY_STOP_DIFF = 'CREATE_GAME/CHANGE_BUY_STOP_DIFF';
const changeBuyStopDiff = buyStopDiff => ({
  type: CHANGE_BUY_STOP_DIFF,
  buyStopDiff
});
const CHANGE_DESCRIPTION = 'CREATE_GAME/CHANGE_DESCRIPTION';
const changeDescription = description => ({
  type: CHANGE_DESCRIPTION,
  description
});
const CLEAR_ERROR = 'CREATE_GAME/CLEAR_ERROR';
const clearError = () => ({
  type: CLEAR_ERROR
});
const CLEAR_FORM = 'CREATE_GAME/CLEAR_FORM';
const clearForm = () => ({
  type: CLEAR_FORM
});
const SET_TEMPLATE = 'CREATE_GAME/SET_TEMPLATE';
const setTemplate = templateId => ({
  type: SET_TEMPLATE,
  templateId
});
const EDIT_GAME = 'CREATE_GAME/EDIT_GAME';
const editGame = (activatedDrawId, groupId) => (dispatch, getState) => {
  const game = getGame(getState(), {
    activatedDrawId,
    groupId
  });
  dispatch({
    type: EDIT_GAME,
    game
  });
};
function getActivatedDrawName(draw) {
  const regCloseTime = new Date(draw.regCloseTime);
  const date = dateFns.format(regCloseTime, 'd/M', {
    locale: dateFns.locales.sv
  });
  switch (draw.productId) {
    case sportProducts.productIds.STRYKTIPSET:
    case sportProducts.productIds.EUROPATIPSET:
      return "Vecka ".concat(getWeek(regCloseTime, {
        locale: dateFns.locales.sv
      })).trim();
    case sportProducts.productIds.BOMBEN:
      return "".concat(draw.drawComment, " ").concat(date);
    case sportProducts.productIds.RACING:
      {
        const formattedRegCloseTime = dateFns.format(regCloseTime, 'dd/M', {
          locale: dateFns.locales.sv
        });
        return "".concat(getProductName(parseGameType(draw)), " ").concat(formattedRegCloseTime);
      }
    default:
      return "".concat(date, " (Vecka ").concat(getWeek(regCloseTime, {
        locale: dateFns.locales.sv
      }), ")");
  }
}
const CREATE_GAME_FAILURE = 'CREATE_GAME/CREATE_GAME_FAILURE';
const CREATE_GAME_INIT = 'CREATE_GAME/CREATE_GAME_INIT';
const CREATE_GAME_SUCCESS = 'CREATE_GAME/CREATE_GAME_SUCCESS';
const createGame = groupId => (dispatch, getState) => {
  dispatch({
    type: CREATE_GAME_INIT
  });
  const gameData = selectors.createGame.getGame(getState()) || {};
  const {
    templateId,
    description,
    productId,
    drawNumber
  } = gameData;
  const draw = getDraw(getState(), {
    productId,
    drawNumber,
    productName: sportProducts.getProdName(productId)
  });
  const template = selectors.gameTemplates.getGameTemplate(getState(), groupId, templateId) || {};
  services.createGame({
    groupId,
    game: _objectSpread(_objectSpread({}, gameData), {}, {
      description: editorToJson(description),
      activatedDrawName: getActivatedDrawName(draw)
    }),
    template
  }, (error, game) => {
    if (error) {
      errorCodeHandler.getErrorMessage(error.code, err => {
        dispatch({
          type: CREATE_GAME_FAILURE,
          errorMessage: err.message
        });
      }, {
        defaultMessage: 'Spelet kunde inte skapas, försök igen.'
      });
      return;
    }
    const rowId = "".concat(groupId, "_").concat(template.productName, "_").concat(gameData.drawNumber);
    const hasBetProposal = Object.keys((getState().CreateBetProposal[rowId] || {}).data || {}).length >= 1;
    dispatch({
      type: CREATE_GAME_SUCCESS,
      game: _objectSpread(_objectSpread(_objectSpread({}, gameData), game), {}, {
        isRetail: true
      }),
      groupId,
      hasBetProposal
    });
    dispatch(createBetProposal(template.productName, gameData.drawNumber, groupId, game.activatedDrawId, gameData.betProposalIsPrivate));
  });
};
const calculateBuyinCloseTime = (regCloseTime, buyStopDiff) => {
  try {
    return subMinutes(new Date(regCloseTime), buyStopDiff);
  } catch (e) {}
};
const UPDATE_GAME_INIT = 'CREATE_GAME/UPDATE_GAME_INIT';
const UPDATE_GAME_SUCCESS = 'CREATE_GAME/UPDATE_GAME_SUCCESS';
const UPDATE_GAME_FAILURE = 'CREATE_GAME/UPDATE_GAME_FAILURE';
const updateGame = function (groupId, activatedDrawId, options) {
  let callback = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : () => null;
  return (dispatch, getState) => {
    const gameData = selectors.createGame.getGame(getState()) || {};
    const {
      description,
      buyStopDiff,
      regCloseTime
    } = gameData;
    const buyinCloseTime = calculateBuyinCloseTime(regCloseTime, buyStopDiff);
    const {
      warnings
    } = errorCodeHandler.constants;
    dispatch(updateBetProposal(activatedDrawId, groupId, {
      alertChanges: false,
      betProposalIsPrivate: gameData.betProposalIsPrivate,
      product: options === null || options === void 0 ? void 0 : options.product
    }));
    services.updateGame({
      activatedDrawId,
      game: _objectSpread(_objectSpread({}, gameData), {}, {
        description: editorToJson(description),
        buyinCloseTime
      })
    }, (error, game) => {
      if (error) {
        errorCodeHandler.getErrorMessage(error.code, err => {
          dispatch({
            type: UPDATE_GAME_FAILURE,
            error,
            groupId,
            activatedDrawId
          });
          dispatch(dialogWrapper.actions.showDialog({
            title: err.message,
            type: 'error'
          }));
          callback(error, null);
        }, {
          overrideErrorMessage: {
            [warnings.INVALID_PARAM]: 'Det ser ut som att felaktig information angavs, se över om något har ändrats och försök igen.'
          }
        });
      } else {
        dispatch({
          type: UPDATE_GAME_SUCCESS,
          game: _objectSpread(_objectSpread({}, game), {}, {
            groupId,
            activatedDrawId
          })
        });
        dispatch(dialogWrapper.actions.showDialog({
          title: 'Ändringarna har sparats.',
          icon: 'checkmark'
        }));
        callback(null, game);
      }
    });
  };
};
const REMOVE_GAME_INIT = 'CREATE_GAME/REMOVE_GAME_INIT';
const REMOVE_GAME_FAILURE = 'CREATE_GAME/REMOVE_GAME_FAILUREFAILURE';
const REMOVE_GAME_SUCCESS = 'CREATE_GAME/REMOVE_GAME_SUCCESS';
const removeGame = (groupId, activatedDrawId) => dispatch => {
  dispatch({
    type: REMOVE_GAME_INIT,
    groupId,
    activatedDrawId
  });
  gameServices.removeGame(activatedDrawId, error => {
    if (error) {
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Spelomgången kunde inte tas bort',
        type: 'error'
      }));
      return dispatch({
        type: REMOVE_GAME_FAILURE,
        groupId,
        activatedDrawId,
        error
      });
    }
    dispatch({
      type: REMOVE_GAME_SUCCESS,
      groupId,
      activatedDrawId
    });
    dispatch(dialogWrapper.actions.showDialog({
      title: 'Spelomgången har raderats',
      icon: 'checkmark'
    }));
  });
};
const NOTIFY_GAME_PARTICIPANTS_INIT = 'CREATE_GAME/NOTIFY_GAME_PARTICIPANTS_INIT';
const NOTIFY_GAME_PARTICIPANTS_SUCCESS = 'CREATE_GAME/NOTIFY_GAME_PARTICIPANTS_SUCCESS';
const NOTIFY_GAME_PARTICIPANTS_FAILURE = 'CREATE_GAME/NOTIFY_GAME_PARTICIPANTS_FAILURE';
const NOTIFY_GAME_PARTICIPANTS_CLEAR = 'CREATE_GAME/NOTIFY_GAME_PARTICIPANTS_CLEAR';
const getGameStatus = (getState, activatedDrawId) => {
  try {
    const groupId = getState().EditorSession.groupId;
    return getState().Games[groupId].data.find(game => game.activatedDrawId === activatedDrawId).status;
  } catch (e) {
    return null;
  }
};
const notifyGameParticipants = (message, activatedDrawId, templateId) => (dispatch, getState) => {
  dispatch({
    type: NOTIFY_GAME_PARTICIPANTS_INIT,
    message,
    activatedDrawId,
    templateId
  });
  const gameStatus = getGameStatus(getState, activatedDrawId);
  services.notifyGameParticipants({
    message,
    activatedDrawId,
    templateId,
    status: gameStatus
  }, error => {
    if (error) {
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Meddelandet kunde inte skickas. Försök igen om en stund eller kontakta kundservice.',
        type: 'error'
      }));
      dispatch({
        type: NOTIFY_GAME_PARTICIPANTS_FAILURE,
        activatedDrawId,
        templateId,
        error
      });
    } else {
      dispatch(dialogWrapper.actions.showDialog({
        title: 'Meddelandet har skickats',
        icon: 'checkmark'
      }));
      dispatch({
        type: NOTIFY_GAME_PARTICIPANTS_SUCCESS,
        message,
        activatedDrawId,
        templateId
      });
    }
  });
};
const clearNotifyGameParticipants = () => dispatch => {
  dispatch({
    type: NOTIFY_GAME_PARTICIPANTS_CLEAR
  });
};
const SET_DATA_FROM_TEMPLATE = 'CREATE_GAME/SET_DATA_FROM_TEMPLATE';
const setDataFromTemplate = (groupId, templateId, drawCloseTime, drawProductId) => (dispatch, getState) => {
  const template = selectors.gameTemplates.getGameTemplate(getState(), groupId, templateId) || {};
  const buyStopDiff = calculateBuyStopDiffFromTemplate(drawCloseTime, template);
  dispatch({
    type: SET_DATA_FROM_TEMPLATE,
    template: buyStopDiff === template.buyStopDiff ? _objectSpread(_objectSpread({}, template), {}, {
      productId: drawProductId || template.productId
    }) : _objectSpread(_objectSpread({}, template), {}, {
      buyStopDiff,
      productId: drawProductId || template.productId
    })
  });
};
const SECONDS = 1000;
const MINUTES = 60;
function calculateBuyStopDiffFromTemplate(drawCloseTime, template) {
  const millisecsUntilDrawClose = new Date(drawCloseTime).getTime() - Date.now();
  const minutesUntilDrawClose = Math.floor(millisecsUntilDrawClose / SECONDS / MINUTES);
  const isDrawClosingTooSoon = minutesUntilDrawClose < template.buyStopDiff;
  if (isDrawClosingTooSoon) {
    const allConfiguredBuyStopDiffs = svs.marketplace_retail_admin.data.initialState.Config.buyStops.map(item => item.minutes);
    const allSelectableBuyStopDiffs = allConfiguredBuyStopDiffs.filter(minutes => minutes <= minutesUntilDrawClose);
    const minConfiguredBuyStopDiff = Math.min(...allConfiguredBuyStopDiffs);
    const maxSelectableBuyStopDiff = Math.max(...allSelectableBuyStopDiffs);
    return allSelectableBuyStopDiffs.length > 0 ? maxSelectableBuyStopDiff : minConfiguredBuyStopDiff;
  }
  return template.buyStopDiff;
}
const TOGGLE_IS_BET_PROPOSAL_PRIVATE = 'CREATE_GAME/TOGGLE_IS_BET_PROPOSAL_PRIVATE';
const toggleBetProposalIsPrivate = () => ({
  type: TOGGLE_IS_BET_PROPOSAL_PRIVATE
});
setGlobal('svs.components.marketplaceRetailAdminData.createGame.actions', {
  CHANGE_DRAW,
  changeDraw,
  CHANGE_BUY_STOP_DIFF,
  changeBuyStopDiff,
  CHANGE_DESCRIPTION,
  changeDescription,
  CLEAR_ERROR,
  clearError,
  CLEAR_FORM,
  clearForm,
  SET_TEMPLATE,
  setTemplate,
  EDIT_GAME,
  editGame,
  CREATE_GAME_FAILURE,
  CREATE_GAME_INIT,
  CREATE_GAME_SUCCESS,
  createGame,
  UPDATE_GAME_FAILURE,
  UPDATE_GAME_INIT,
  UPDATE_GAME_SUCCESS,
  updateGame,
  REMOVE_GAME_INIT,
  REMOVE_GAME_SUCCESS,
  REMOVE_GAME_FAILURE,
  removeGame,
  NOTIFY_GAME_PARTICIPANTS_INIT,
  NOTIFY_GAME_PARTICIPANTS_SUCCESS,
  NOTIFY_GAME_PARTICIPANTS_FAILURE,
  NOTIFY_GAME_PARTICIPANTS_CLEAR,
  notifyGameParticipants,
  clearNotifyGameParticipants,
  SET_DATA_FROM_TEMPLATE,
  setDataFromTemplate,
  TOGGLE_IS_BET_PROPOSAL_PRIVATE,
  toggleBetProposalIsPrivate,
  testInternals: {
    calculateBuyStopDiffFromTemplate
  }
});

 })(window);