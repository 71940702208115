(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/create-game/assets/javascripts/services/notify-game-participants.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/create-game/assets/javascripts/services/notify-game-participants.js');
"use strict";

const _excluded = ["status"];
function _objectWithoutProperties(e, t) { if (null == e) return {}; var o, r, i = _objectWithoutPropertiesLoose(e, t); if (Object.getOwnPropertySymbols) { var s = Object.getOwnPropertySymbols(e); for (r = 0; r < s.length; r++) o = s[r], t.includes(o) || {}.propertyIsEnumerable.call(e, o) && (i[o] = e[o]); } return i; }
function _objectWithoutPropertiesLoose(r, e) { if (null == r) return {}; var t = {}; for (var n in r) if ({}.hasOwnProperty.call(r, n)) { if (e.includes(n)) continue; t[n] = r[n]; } return t; }

const {
  services
} = svs.components.marketplaceRetailAdminData.createGame;
const {
  GAME_STATUS_FINISHED
} = svs.components.marketplaceData.gamesConstants;
const notifyGameParticipants = (data, callback) => {
  const _ref = data || {},
    {
      status
    } = _ref,
    notifyData = _objectWithoutProperties(_ref, _excluded);
  if (status && [GAME_STATUS_FINISHED].indexOf(status) > -1) {
    return services.notifySettledBetsParticipants(notifyData, callback);
  }
  return services.notifyActivatedDrawsParticipants(notifyData, callback);
};
function notify(path, data, callback) {
  const {
    activatedDrawId,
    templateId,
    message
  } = data || {};
  if (!activatedDrawId || !templateId) {
    return callback(new Error("Mandatory parameters, missing or invalid value,\n        activatedDrawId: ".concat(activatedDrawId, ",\n        templateId: ").concat(templateId)));
  }
  svs.core.jupiter.put({
    path,
    data: {
      message,
      type: 'Generic',
      containerId: templateId,
      activatedDrawId
    }
  }, response => {
    callback(undefined, response);
  }, error => {
    callback(error);
  });
}
const notifyActivatedDrawsParticipants = (data, callback) => notify("/retailer/1/marketplace/notify/activateddraws", data, callback);
const notifySettledBetsParticipants = (data, callback) => notify("/retailer/1/marketplace/notify/settledbets", data, callback);
setGlobal('svs.components.marketplaceRetailAdminData.createGame.services.notifyGameParticipants', notifyGameParticipants);
setGlobal('svs.components.marketplaceRetailAdminData.createGame.services.notifySettledBetsParticipants', notifySettledBetsParticipants);
setGlobal('svs.components.marketplaceRetailAdminData.createGame.services.notifyActivatedDrawsParticipants', notifyActivatedDrawsParticipants);

 })(window);