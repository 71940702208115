(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/marketplace-retail-admin-data/create-game/assets/javascripts/services/create-game.js') >= 0) return;  svs.modules.push('/components/marketplace-retail-admin-data/create-game/assets/javascripts/services/create-game.js');
"use strict";


const {
  jupiter
} = svs.core;
const createGame = (_ref, callback) => {
  let {
    groupId,
    game,
    template
  } = _ref;
  jupiter.post({
    path: "/retailer/1/marketplace/groups/".concat(groupId, "/activateddraws"),
    data: {
      productId: game.productId || template.productId,
      drawNumber: game.drawNumber,
      containerId: game.templateId,
      buyinCloseMinBeforeRegclose: game.buyStopDiff,
      activatedDrawDescription: game.description || '',
      activatedDrawName: game.activatedDrawName
    }
  }, response => {
    const {
      CreateRetailerActivatedDrawReply: {
        activatedDrawId
      }
    } = response;
    callback(null, {
      activatedDrawId
    });
  }, err => callback(err.responseJSON.error));
};
setGlobal('svs.components.marketplaceRetailAdminData.createGame.services.createGame', createGame);

 })(window);